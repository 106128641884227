import { getElement } from "./utilis.js";
import ScrollReveal from "scrollreveal";

document.addEventListener("DOMContentLoaded", function () {
  ScrollReveal().reveal(".animate-img", {
    distance: "200px",
    opacity: 0.8,
  });

  const titles = getElement(".animate-title", true);
  const paragraphs = getElement(".animate-para", true);
  const downloadBtn = getElement(".downloadBtn");
  const name = getElement(".name", true);
  const info = getElement(".info", true);
  const skills = getElement(".skills", true);
  const projects = getElement(".project-card", true);
  const contact = getElement(".contact", true);
  const map = getElement("#map");
  const socials = getElement(".socials", true);

  const revealFromTopRight = {
    origin: "top",
    distance: "50px",
    duration: 800,
    delay: 100,
    easing: "cubic-bezier(.78, .13, .15, .86)",
  };

  const revealFromBottom = {
    origin: "bottom",
    distance: "50px",
    duration: 1000,
    delay: 200,
    easing: "cubic-bezier(0.5, 0, 0, 1)",
  };

  const revealFromBehind = {
    origin: "right",
    distance: "50px",
    duration: 700,
    delay: 150,
    easing: "cubic-bezier(0.895, 0.03, 0.685, 0.22);",
  };

  const revealFromFront = {
    scale: 0.9,
    opacity: 0,
    duration: 800,
    delay: 200,
    easing: "cubic-bezier(0.5, 0, 0, 1)",
  };

  const revealWithCustomEasing = {
    origin: "left",
    distance: "50px",
    duration: 800,
    delay: 100,
    easing: "cubic-bezier(0.42, 0, 0.58, 1)",
  };

  const revealWithCustomEasing2 = {
    origin: "left",
    distance: "50px",
    duration: 800,
    delay: 100,
    easing: "cubic-bezier(.63,.21,.48,1.82)",
  };

  const revealWithCustomEasing3 = {
    origin: "left",
    distance: "50px",
    duration: 800,
    delay: 100,
    easing: "cubic-bezier(0.6, 0.04, 0.98, 0.335)",
  };

  const reveals = [
    revealFromTopRight,
    revealFromBottom,
    revealFromBehind,
    revealFromFront,
    revealWithCustomEasing,
    revealWithCustomEasing2,
    revealWithCustomEasing3,
  ];

  addRevealToElement(downloadBtn, revealWithCustomEasing2);
  addRevealToElement(contact[0], revealFromFront);
  addRevealToElement(contact[1], revealWithCustomEasing3);
  addRevealToElement(map, revealFromTopRight);
  addRevealToElement(socials, revealWithCustomEasing2);

  titles.forEach((element, index) => {
    addRevealToElement(element, reveals[index]);
  });

  paragraphs.forEach((element, index) => {
    addRevealToElement(element, reveals[index]);
  });

  name.forEach((element, index) => {
    addRevealToElement(element, reveals[index]);
  });
  addRevealToElement(downloadBtn, revealWithCustomEasing2);

  info.forEach((element, index) => {
    addRevealToElement(element, reveals[index]);
  });

  //! If we have more elements then the array of reveals, we can use the modulo operator to loop through the array of reveals even if the elements are more than the reveals.

  skills.forEach((skill, index) => {
    addRevealToElement(skill, reveals[index % reveals.length]);
  });

  projects.forEach((project, index) => {
    addRevealToElement(project, reveals[index % reveals.length]);
  });
});

function addRevealToElement(element, options) {
  ScrollReveal().reveal(element, options);
}
