import Radu_Tapelea from './Radu_Tapelea.pdf';
import logo from './imgs/logo.png';
import {createElement} from './utilis.js';

export default function nav() {
	const header = createElement({
		type: 'header',
		classes: 'text-primary container mx-auto min-h-10',
		content: `
        <div class="container mx-auto flex flex-wrap p-5 items-center justify-between md:justify-normal">
            <div class="relative flex w-20 h-10">
                <a href="#home">
                    <img src="${logo}" alt="Icon" class="w-20 absolute">
                </a>
            </div>
            <nav class="md:ml-auto hidden  md:flex flex-wrap items-center text-base justify-center">
                <a href="#home" class="mr-5 cursor-pointer noice hover:noice">Home</a>
                <a href="#hero" class="mr-5 cursor-pointer noice hover:noice">About</a>
                <a href="#projects" class="mr-5 cursor-pointer noice hover:noice">Projects</a>
                <a href="#contact" class="mr-5 cursor-pointer noice hover:noice">Contact</a>
            </nav>
            <div class="relative  flex flex-col md:hidden min-h-20 w-20 cursor-pointer items-center justify-center rounded-3xl p-2"
                id="hamburgher">
                <div class="space-y-2">
                    <span id="line1"
                        class="block h-1 w-10 origin-center rounded-full bg-secondary transition-transform ease-in-out duration-200 "></span>
                    <span id="line2"
                        class="block h-1 w-8 origin-center rounded-full bg-primary transition-transform ease-in-out"></span>
                </div>
                <nav id="menu"
                    class="absolute top-20 hidden z-10 flex-col w-40 items-center transition-all ease-in-out animate-fadeIn duration-200">
                    <a href="#home" class="mt-5 text-center cursor-pointer noice hover:noice ">Home</a>
                    <a href="#hero" class="mt-5 text-center cursor-pointer noice hover:noice ">About</a>
                    <a href="#projects" class="mt-5 text-center cursor-pointer noice hover:noice ">Projects</a>
                    <a href="#contact" class="mt-5 text-center cursor-pointer noice hover:noice ">Contact</a>
                </nav>
            </div>
            <a href="${Radu_Tapelea}" targer='_blank' class="btn hidden md:inline-flex text-primary">
                    View CV
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-4 h-4 ml-1 mb-1" viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
            </a>
        </div>
        `,
	});

	const hamburgherMenu = header.querySelector('#hamburgher');

	hamburgherMenu.addEventListener('click', toggleMenu);

	function toggleMenu() {
		const line1 = document.getElementById('line1');
		const line2 = document.getElementById('line2');

		line1.classList.toggle('translate-y-1.5');
		line1.classList.toggle('rotate-45');
		line2.classList.toggle('w-10');
		line2.classList.toggle('w-8');
		line2.classList.toggle('-translate-y-1.5');
		line2.classList.toggle('-rotate-45');

		const menu = document.getElementById('menu');
		menu.classList.toggle('hidden');
		menu.classList.toggle('flex');
	}

	return header;
}
