import Radu_Tapelea from './Radu_Tapelea.pdf';
import heroImg from './imgs/hero.png';
import {createElement} from './utilis.js';

export default function hero() {
	const hero = createElement({
		type: 'section',
		id: 'hero',
		classes:
			'mt-40 md:mt-0 min-h-screen w-screen flex flex-col items-center justify-evenly overlap-x-hidden relative',
		content: `
        <div class="flex flex-col md:flex-row items-center justify-around w-full">
            <div class="flex flex-col justify-around h-80">
                <div class="flex flex-col animate-title">
                    <h1 class=" animate text-4xl text-white">Hi there,</h1>
                    <h1 class=" animate text-4xl mt-2 text-white">I'm <span class="text-primary text-shadow-primaryHover animate-flicker name">Radu Țapelea<span></h1>
                    <p class="animate-para animate mt-2 text-primary">Frontend Developer</p>
                </div>
                <div class="downloadBtn cursor-pointer">
                    <a href="${Radu_Tapelea}" download="./Radu .pdfRadu.pdf" class="btn inline-flex text-primary">Download
                        CV
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-4 h-4 ml-1 mb-1" viewBox="0 0 24 24">
                            <path d="M12 5v14M5 12l7 7 7-7"></path>
                        </svg>
                    </a>
                </div>
            </div>
            <div class="relative h-96 w-96 animate animate-img mt-10 md:mt-0">
                <div class="w-80 h-80 rounded-full shadow-xl shadow-secondary bg-secondary mx-auto">
                </div>
                <img src="${heroImg}" alt="Radu" class="center h-80 animate-pulsate-fwd">
            </div>
        </div>

        <div class="w-ful flex flex-col items-center ">
            <h1 class="animate-title text-5xl text-primary">About</h1>
            <p class="animate-para w-2/4 mt-6 text-primary text-shadow-secondaryHover">
             Hi there, I'm <span class="text-xl animate-flicker name"> Radu Țapelea</span>, a self thought Frontend Developer based in UK. I have a passion for web development and love to create websites and web applications that are both beautiful and functional. I am always looking for new opportunities to learn and grow as a developer.
            </p>
            <p class="animate-para animate w-2/4 mt-6 text-primary text-shadow-secondary">
            Fostering a deep affection for cats, particularly drawn to the distinct allure of the Sphynx breed, I derive immense joy from their companionship. Concurrently, my fervent devotion to fiction, science fiction, and fantasy literature serves as a gateway to limitless realms of imagination and fascination, enriching my life with endless adventures and discoveries.
            </p>
        </div>

        <div class="flex justify-around items-center flex-wrap container mx-auto md:max-w-4xl mt-10">

        <div class="min-w-36 min-h-36 rounded-2xl flex flex-col items-center justify-around info">
            <div class="w-10 h-10 bg-secondary rounded-full flex flex-col items-center justify-center">
                <div class="w-2 h-2 bg-primary rounded-full"></div>
            </div>

            <div class="flex flex-col items-center justify-between">
                <h1 class="text-xl font-bold text-primary">Email Address</h1>
                <a href="mailto:tsraduu@gmail.com?subject=I'de love to get in touch!&body=Hi Radu, I came across your portfolio and I would like to get in touch for further communication and potentially work together!" class=" font-light text-primary">tsraduu@gmail.com</a>
            </div>
        </div>

        <div class="min-w-36 min-h-36 rounded-2xl flex flex-col items-center justify-around info">
            <div class="w-10 h-10 bg-secondary rounded-full flex flex-col items-center justify-center">
                <div class="w-2 h-2 bg-primary rounded-full"></div>
            </div>

            <div class="flex flex-col items-center justify-between">
                <h1 class="text-xl font-bold text-primary">Phone number</h1>
                <a href="tel:+447383556141" class="group font-light text-primary">+44<span
                        class="opacity-0 group-hover:opacity-100"> 738 3556 141</span></a>
            </div>
        </div>

        <div class="min-w-36 min-h-36 rounded-2xl flex flex-col items-center justify-around info">
            <div class="w-10 h-10 bg-secondary rounded-full flex flex-col items-center justify-center">
                <div class="w-2 h-2 bg-primary rounded-full"></div>
            </div>

            <div class="flex flex-col items-center justify-between">
                <h1 class="text-xl font-bold text-primary">Linkedin</h1>
                <a href="https://www.linkedin.com/in/radu-tapelea/" target="_blank" class="font-light text-primary p-1 hover:text-secondary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                        fill="currentColor">
                        <path
                            d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z" />
                    </svg>
                </a>
            </div>
        </div>

        <div class="min-w-36 min-h-36 rounded-2xl flex flex-col items-center justify-around info">
            <div class="w-10 h-10 bg-secondary rounded-full flex flex-col items-center justify-center">
                <div class="w-2 h-2 bg-primary rounded-full"></div>
            </div>


            <div class="flex flex-col items-center justify-between">
                <h1 class="text-xl font-bold text-primary">GitHub</h1>
                <a href="https://github.com/s-Radu" target="_blank" class="font-light text-primary p-1 hover:text-secondary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                        fill="currentColor">
                        <path
                            d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                    </svg>
                </a>
            </div>
        </div>

    </div>

    <div class="socials flex flex-col gap-4 items-center min-h-40 absolute right-2 top-2/4 md:right-10 md:top-1/4">
        <span class="border-2 border-primary rounded-2xl h-20 w-0"></span>
        <a href="https://github.com/s-Radu" target="_blank" class="font-light text-primary p-1 hover:text-secondary">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path
                    d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
            </svg>
        </a>

        <a href="https://www.linkedin.com/in/radu-tapelea/" target="_blank"
            class="font-light text-primary p-1 hover:text-secondary">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path
                    d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z" />
            </svg>
        </a>

        <a href="https://www.instagram.com/rthevoid/" class="font-light text-primary p-1 hover:text-secondary">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                        fill="currentColor"></path>
                    <path
                        d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"
                        fill="currentColor"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M1.65396 4.27606C1 5.55953 1 7.23969 1 10.6V13.4C1 16.7603 1 18.4405 1.65396 19.7239C2.2292 20.8529 3.14708 21.7708 4.27606 22.346C5.55953 23 7.23969 23 10.6 23H13.4C16.7603 23 18.4405 23 19.7239 22.346C20.8529 21.7708 21.7708 20.8529 22.346 19.7239C23 18.4405 23 16.7603 23 13.4V10.6C23 7.23969 23 5.55953 22.346 4.27606C21.7708 3.14708 20.8529 2.2292 19.7239 1.65396C18.4405 1 16.7603 1 13.4 1H10.6C7.23969 1 5.55953 1 4.27606 1.65396C3.14708 2.2292 2.2292 3.14708 1.65396 4.27606ZM13.4 3H10.6C8.88684 3 7.72225 3.00156 6.82208 3.0751C5.94524 3.14674 5.49684 3.27659 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404C3.27659 5.49684 3.14674 5.94524 3.0751 6.82208C3.00156 7.72225 3 8.88684 3 10.6V13.4C3 15.1132 3.00156 16.2777 3.0751 17.1779C3.14674 18.0548 3.27659 18.5032 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.49684 20.7234 5.94524 20.8533 6.82208 20.9249C7.72225 20.9984 8.88684 21 10.6 21H13.4C15.1132 21 16.2777 20.9984 17.1779 20.9249C18.0548 20.8533 18.5032 20.7234 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.7234 18.5032 20.8533 18.0548 20.9249 17.1779C20.9984 16.2777 21 15.1132 21 13.4V10.6C21 8.88684 20.9984 7.72225 20.9249 6.82208C20.8533 5.94524 20.7234 5.49684 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C18.5032 3.27659 18.0548 3.14674 17.1779 3.0751C16.2777 3.00156 15.1132 3 13.4 3Z"
                        fill="currentColor"></path>
                </g>
            </svg>
        </a>
        <span class="border-2 border-primary rounded-2xl h-20 w-0"></span>
    </div>
`,
	});

	return hero;
}
