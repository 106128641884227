import { getElement } from "./utilis.js";

import "./style.css";
import "./scrollReveal.js";
import nav from "./nav.js";
import hero from "./hero.js";
import skills from "./skills.js";
import projects from "./projects.js";
import contact, { initMap } from "./contact.js";
import footer from "./footer.js";
import button from "./scrollToTop.js";

(function () {
  const parentElement = document.body;

  const pageElements = [nav, hero, skills, projects, contact, button, footer];

  pageElements.forEach((element) => {
    const node = element();
    if (node instanceof Node) {
      parentElement.appendChild(node);
    } else {
      console.error("Element is not a Node:", node);
    }
  });

  //* Smooth scrolling since tailwindcss is a lazy bastard and does not wanna do it :(

  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    });
  });
  // initMap();
})();
