import { createElement } from "./utilis";

export default function skillsPage() {
  const page = createElement({
    type: "section",
    id: "skills",
    classes:
      "flex flex-col items-center text-primary bg-secondary container mx-auto rounded-2xl mt-10 p-6 ",
    content: `
        <h1 class="animate-title text-5xl m-4 mb-10">
            Skills
        </h1>
        <div class="flex max-w-3xl flex-wrap justify-center gap-4 ">
            <div class="skills flex flex-col items-center">
                <svg viewBox="-1 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor" width="30" height="30">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Dribbble-Light-Preview" transform="translate(-61.000000, -7639.000000)"
                                fill="currentColor">
                                <g id="icons" transform="translate(56.000000, 160.000000)">
                                    <path
                                        d="M19.4350881,7485 L19.4279481,7485 L10.8119794,7485 L11.0180201,7487 L19.2300674,7487 C19.109707,7488.752 18.7455658,7492.464 18.6119454,7494.153 L13.99949,7495.451 L13.99949,7495.455 L13.98929,7495.46 L9.37377458,7493.836 L9.05757353,7490 L11.3199411,7490 L11.4800816,7492.063 L13.99337,7493 L13.99949,7493 L16.5086984,7492.1 L16.7667592,7489 L8.95659319,7489 C8.91885306,7488.599 8.43333144,7483.392 8.34867116,7483 L19.6370488,7483 C19.5738086,7483.66 19.5095484,7484.338 19.4350881,7485 L19.4350881,7485 Z M5,7479 L6.63812546,7497.148 L13.98929,7499 L21.3598345,7497.111 L23,7479 L5,7479 Z"
                                        id="html-[#363636]"> </path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>

                <p class="m-4">
                    HTML
                </p>
            </div>
            
            <div class="skills flex flex-col items-center">
                <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enable-background="new 0 0 512 512"
                    xml:space="preserve" width="30" height="30">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <g id="c133de6af664cd4f011a55de6b001b19">
                            <path display="inline"
                                d="M483.111,0.501l-42.59,461.314l-184.524,49.684L71.47,461.815L28.889,0.501H483.111z M397.29,94.302 H255.831H111.866l6.885,55.708h137.08h7.7l-7.7,3.205l-132.07,55.006l4.38,54.453l127.69,0.414l68.438,0.217l-4.381,72.606 l-64.058,18.035v-0.057l-0.525,0.146l-61.864-15.617l-3.754-45.07h-0.205H132.1h-0.202l7.511,87.007l116.423,34.429v-0.062 l0.21,0.062l115.799-33.802l15.021-172.761h-131.03h-0.323l0.323-0.14l135.83-58.071L397.29,94.302z">
                            </path>
                        </g>
                    </g>
                </svg>

                <p class="m-4">
                    CSS
                </p>
            </div>

            <div class="skills flex flex-col items-center">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="30" height="30">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path
                            d="M12,2A10,10,0,1,1,2,12,10,10,0,0,1,12,2M10,15.33a2.55,2.55,0,0,1,0,1.5c0,.05,0,.1-.06.17s0,.07-.07.12a2.5,2.5,0,0,1-.46.67c-.58.64-1.41.88-1.74.68s-.17-1.12.49-1.83a6.91,6.91,0,0,1,1.76-1.26h0l.08-.05m8.27-9c-.45-1.78-3.4-2.36-6.18-1.37A14,14,0,0,0,7.34,7.63c-1.53,1.44-1.78,2.69-1.68,3.21.34,1.84,2.88,3.05,3.92,3.94h0c-.3.15-2.54,1.28-3.08,2.44A1.62,1.62,0,0,0,7,19.45a3.16,3.16,0,0,0,3.41-1.38A3.44,3.44,0,0,0,10.75,15a4.34,4.34,0,0,1,1.53-.08c1.76.21,2.1,1.3,2,1.76a1.16,1.16,0,0,1-.55.79c-.12.07-.16.1-.15.16s.07.08.17.06a1.36,1.36,0,0,0,1-1.22c0-1.08-1-2.28-2.81-2.25a4.84,4.84,0,0,0-1.56.22l-.08-.09c-1.13-1.2-3.21-2-3.12-3.67,0-.59.23-2.13,4-4,3.08-1.54,5.55-1.12,6-.17.61,1.33-1.32,3.82-4.52,4.18a2.44,2.44,0,0,1-2-.51c-.17-.17-.19-.2-.25-.17s0,.23,0,.33a2,2,0,0,0,1.15.91,6.18,6.18,0,0,0,3.76-.37C17.2,10.12,18.72,8,18.27,6.28Z">
                        </path>
                    </g>
                </svg>
                <p class="m-4">
                    SASS
                </p>
            </div>

            <div class="skills flex flex-col items-center">
                <svg fill="currentColor" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" width="30"
                    height="30">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path
                            d="M17.313 14.789h-2.809c0 2.422-0.011 4.829-0.011 7.254 0.033 0.329 0.051 0.712 0.051 1.099 0 0.81-0.081 1.601-0.236 2.365l0.013-0.076c-0.412 0.861-1.475 0.751-1.957 0.6-0.451-0.242-0.808-0.609-1.031-1.055l-0.006-0.014c-0.044-0.094-0.097-0.174-0.16-0.246l0.001 0.001-2.281 1.406c0.367 0.79 0.934 1.434 1.637 1.885l0.018 0.011c0.763 0.427 1.675 0.678 2.645 0.678 0.484 0 0.954-0.063 1.401-0.18l-0.038 0.009c0.988-0.248 1.793-0.89 2.254-1.744l0.009-0.019c0.359-0.914 0.566-1.973 0.566-3.080 0-0.388-0.026-0.77-0.075-1.145l0.005 0.044c0.015-2.567 0-5.135 0-7.722zM28.539 23.843c-0.219-1.368-1.11-2.518-3.753-3.59-0.92-0.431-1.942-0.731-2.246-1.425-0.063-0.158-0.099-0.341-0.099-0.532 0-0.124 0.015-0.244 0.044-0.359l-0.002 0.010c0.208-0.55 0.731-0.935 1.343-0.935 0.199 0 0.388 0.040 0.559 0.113l-0.009-0.004c0.552 0.19 0.988 0.594 1.215 1.112l0.005 0.013c1.292-0.845 1.292-0.845 2.193-1.406-0.216-0.369-0.459-0.689-0.734-0.977l0.002 0.002c-0.767-0.814-1.852-1.32-3.056-1.32-0.171 0-0.34 0.010-0.505 0.030l0.020-0.002-0.881 0.111c-0.856 0.194-1.587 0.639-2.133 1.252l-0.003 0.004c-0.535 0.665-0.859 1.519-0.859 2.449 0 1.279 0.613 2.415 1.56 3.131l0.010 0.007c1.706 1.275 4.2 1.555 4.519 2.755 0.3 1.462-1.087 1.931-2.457 1.762-0.957-0.218-1.741-0.83-2.184-1.652l-0.009-0.017-2.287 1.313c0.269 0.536 0.607 0.994 1.011 1.385l0.001 0.001c2.174 2.194 7.61 2.082 8.586-1.255 0.113-0.364 0.178-0.782 0.178-1.215 0-0.3-0.031-0.593-0.091-0.875l0.005 0.028zM1.004 1.004h29.991v29.991h-29.991z">
                        </path>
                    </g>
                </svg>
                <p class="m-4">
                    JavaScript
                </p>
            </div>

            <div class="skills flex flex-col items-center">
                <svg fill="currentColor" viewBox="0 -0.5 25 25" xmlns="http://www.w3.org/2000/svg" width="30"
                    height="30">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path
                            d="m1.582 5.638c-2.206 3.17-1.931 7.294-.246 10.663.04.08.08.16.123.24.026.05.051.102.08.151.015.03.033.059.049.087.029.054.058.105.088.16q.08.138.16.275c.03.05.06.101.092.151q.091.147.186.292c.026.042.052.08.08.122.088.131.178.262.27.39l.008.01c.014.021.031.041.046.062.08.11.16.22.246.328l.093.118q.112.141.228.28l.087.104c.103.122.209.24.32.362l.006.006.012.013c.106.115.214.228.32.34l.103.104c.085.085.17.169.258.252.035.034.07.068.106.1.116.11.234.217.353.32.002.002.003.004.006.005.02.018.042.035.062.052q.16.137.32.271l.133.106q.132.106.267.209l.143.109c.099.073.199.145.3.214.037.026.072.052.11.08l.03.022c.096.066.194.13.291.193.042.028.08.057.126.08.15.094.302.189.456.279l.126.071c.113.066.226.13.342.192.062.034.126.066.189.098.08.043.16.086.245.127l.057.027.1.049q.192.092.388.18l.08.037q.224.098.453.189l.11.043c.141.054.284.108.427.16l.054.018c.16.055.32.106.48.156l.115.034c.128.046.292.094.461.132l.031.006c10.667 1.945 13.766-6.41 13.766-6.41-2.602 3.39-7.222 4.285-11.6 3.289-.166-.038-.326-.089-.489-.137l-.122-.037q-.24-.073-.47-.153l-.065-.023c-.139-.049-.278-.101-.414-.154l-.116-.046c-.15-.059-.3-.122-.448-.186l-.09-.04c-.127-.058-.253-.115-.378-.175l-.109-.053c-.098-.048-.195-.098-.292-.149-.065-.034-.131-.066-.195-.102-.118-.063-.234-.13-.35-.197l-.118-.066c-.154-.09-.306-.185-.456-.279-.042-.026-.08-.054-.123-.08-.109-.07-.218-.142-.325-.216-.035-.023-.07-.05-.105-.074q-.155-.109-.307-.222c-.046-.034-.091-.069-.136-.103-.093-.071-.186-.142-.277-.217l-.123-.099c-.116-.096-.231-.191-.345-.29l-.038-.032c-.122-.108-.24-.218-.362-.33l-.102-.098q-.133-.126-.26-.255l-.102-.102q-.16-.167-.32-.338l-.016-.017c-.11-.121-.218-.244-.324-.369l-.083-.102c-.08-.095-.16-.191-.233-.288-.029-.034-.058-.07-.086-.106-.092-.119-.182-.24-.27-.358-2.43-3.314-3.303-7.886-1.36-11.64z">
                        </path>
                        <path
                            d="m8.429 2.966c-.754 1.166-1.202 2.592-1.202 4.121 0 1.347.347 2.613.958 3.714l-.02-.039c.235.457.469.843.727 1.21l-.022-.032c.244.387.515.723.82 1.024l.001.001c.115.126.235.25.358.371.031.032.062.062.094.094.119.115.24.229.366.339.005.004.01.01.015.013.138.122.282.24.427.353l.097.076c.146.112.294.222.446.326l.013.01c.067.046.136.09.204.134.033.022.064.044.097.064.109.07.219.138.331.203l.046.027c.097.056.194.11.293.16.034.019.069.036.104.054.068.035.136.071.205.106l.03.014c.141.07.282.136.427.199.031.014.062.026.094.039.115.049.233.097.35.142l.15.055c.106.039.214.076.32.112l.146.046c.126.048.281.094.441.13l.024.004c8.236 1.365 10.138-4.977 10.138-4.977-1.714 2.468-5.034 3.646-8.575 2.727-.16-.042-.312-.086-.466-.135-.047-.014-.094-.03-.14-.046-.11-.035-.218-.074-.326-.113l-.147-.054c-.118-.046-.235-.093-.351-.142-.032-.014-.064-.026-.094-.04-.146-.063-.289-.13-.43-.199-.073-.036-.144-.074-.215-.11l-.124-.064q-.138-.075-.273-.154c-.021-.012-.043-.024-.065-.037q-.167-.098-.33-.202c-.034-.022-.066-.045-.099-.066-.072-.046-.143-.094-.214-.142-.152-.105-.299-.214-.445-.326l-.1-.08c-1.549-1.211-2.718-2.843-3.34-4.723l-.02-.07c-.236-.694-.373-1.494-.373-2.325 0-1.338.353-2.594.972-3.679l-.019.037z">
                        </path>
                        <path
                            d="m14.188.97c-.504.747-.804 1.668-.804 2.659 0 .715.156 1.393.436 2.002l-.012-.03c.73 1.627 2.059 2.876 3.699 3.484l.045.015c.068.026.135.048.205.071l.09.028c.078.029.176.058.276.081l.017.003c4.547.88 5.781-2.334 6.11-2.806-1.081 1.556-2.896 1.929-5.124 1.389-.176-.043-.37-.106-.539-.167-.262-.093-.474-.183-.679-.283l.039.017c-.428-.209-.795-.436-1.136-.697l.016.012c-1.994-1.513-3.232-4.4-1.931-6.749z">
                        </path>
                    </g>
                </svg>
                <p class="m-4">
                    jQuery
                </p>
            </div>

            <div class="skills flex flex-col items-center">
                <svg fill="currentColor" viewBox="-1 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path
                            d="m20.952 18.5-9.714 5.5v-4.286l6.048-3.334zm.666-.595v-11.52l-3.548 2.071v7.381l3.548 2.048zm-20.952.595 9.714 5.5v-4.286l-6.048-3.334zm-.666-.595v-11.52l3.548 2.071v7.381zm.405-12.262 9.976-5.643v4.143l-6.429 3.548zm20.809 0-9.976-5.643v4.143l6.428 3.548zm-10.833 13.095-5.972-3.287v-6.524l5.976 3.452zm.857 0 5.976-3.286v-6.524l-5.976 3.452zm-6.429-10.548 6-3.31 6 3.31-6 3.453z">
                        </path>
                    </g>
                </svg>
                <p class="m-4">
                    Webpack
                </p>
            </div>

            <div class="skills flex flex-col items-center">
            </div>
            <div class="skills flex flex-col items-center">
                <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M13.4142 3.82843C12.6332 3.04738 11.3668 3.04738 10.5858 3.82843L9.91421 4.5L11.482 6.06774C11.6472 6.02356 11.8208 6 12 6C13.1046 6 14 6.89543 14 8C14 8.17916 13.9764 8.35282 13.9323 8.51804L15.982 10.5677C16.1472 10.5236 16.3208 10.5 16.5 10.5C17.6046 10.5 18.5 11.3954 18.5 12.5C18.5 13.6046 17.6046 14.5 16.5 14.5C15.3954 14.5 14.5 13.6046 14.5 12.5C14.5 12.3208 14.5236 12.1472 14.5677 11.982L13 10.4142V15.2676C13.5978 15.6134 14 16.2597 14 17C14 18.1046 13.1046 19 12 19C10.8954 19 10 18.1046 10 17C10 16.2597 10.4022 15.6134 11 15.2676V9.73244C10.4022 9.38663 10 8.74028 10 8C10 7.82084 10.0236 7.64718 10.0677 7.48196L8.5 5.91421L3.82843 10.5858C3.04738 11.3668 3.04738 12.6332 3.82843 13.4142L10.5858 20.1716C11.3668 20.9526 12.6332 20.9526 13.4142 20.1716L20.1716 13.4142C20.9526 12.6332 20.9526 11.3668 20.1716 10.5858L13.4142 3.82843ZM9.17157 2.41421C10.7337 0.852115 13.2663 0.852119 14.8284 2.41422L21.5858 9.17157C23.1479 10.7337 23.1479 13.2663 21.5858 14.8284L14.8284 21.5858C13.2663 23.1479 10.7337 23.1479 9.17157 21.5858L2.41421 14.8284C0.852115 13.2663 0.852119 10.7337 2.41422 9.17157L9.17157 2.41421Z"
                            fill="currentColor"></path>
                    </g>
                </svg>
                <p class="m-4">
                    Git</p>
            </div>

            <div class="skills flex flex-col items-center">
                <svg viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor" width="30" height="30">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Dribbble-Light-Preview" transform="translate(-140.000000, -7559.000000)"
                                fill="currentColor">
                                <g id="icons" transform="translate(56.000000, 160.000000)">
                                    <path
                                        d="M94,7399 C99.523,7399 104,7403.59 104,7409.253 C104,7413.782 101.138,7417.624 97.167,7418.981 C96.66,7419.082 96.48,7418.762 96.48,7418.489 C96.48,7418.151 96.492,7417.047 96.492,7415.675 C96.492,7414.719 96.172,7414.095 95.813,7413.777 C98.04,7413.523 100.38,7412.656 100.38,7408.718 C100.38,7407.598 99.992,7406.684 99.35,7405.966 C99.454,7405.707 99.797,7404.664 99.252,7403.252 C99.252,7403.252 98.414,7402.977 96.505,7404.303 C95.706,7404.076 94.85,7403.962 94,7403.958 C93.15,7403.962 92.295,7404.076 91.497,7404.303 C89.586,7402.977 88.746,7403.252 88.746,7403.252 C88.203,7404.664 88.546,7405.707 88.649,7405.966 C88.01,7406.684 87.619,7407.598 87.619,7408.718 C87.619,7412.646 89.954,7413.526 92.175,7413.785 C91.889,7414.041 91.63,7414.493 91.54,7415.156 C90.97,7415.418 89.522,7415.871 88.63,7414.304 C88.63,7414.304 88.101,7413.319 87.097,7413.247 C87.097,7413.247 86.122,7413.234 87.029,7413.87 C87.029,7413.87 87.684,7414.185 88.139,7415.37 C88.139,7415.37 88.726,7417.2 91.508,7416.58 C91.513,7417.437 91.522,7418.245 91.522,7418.489 C91.522,7418.76 91.338,7419.077 90.839,7418.982 C86.865,7417.627 84,7413.783 84,7409.253 C84,7403.59 88.478,7399 94,7399"
                                        id="github-[#142]"> </path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <p class="m-4">
                    GitHub
                </p>
            </div>

            <div class="skills flex flex-col items-center">
                <svg fill="currentColor" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg" width="30"
                    height="30">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path
                            d="M12.001,4.8c-3.2,0-5.2,1.6-6,4.8c1.2-1.6,2.6-2.2,4.2-1.8c0.913,0.228,1.565,0.89,2.288,1.624 C13.666,10.618,15.027,12,18.001,12c3.2,0,5.2-1.6,6-4.8c-1.2,1.6-2.6,2.2-4.2,1.8c-0.913-0.228-1.565-0.89-2.288-1.624 C16.337,6.182,14.976,4.8,12.001,4.8z M6.001,12c-3.2,0-5.2,1.6-6,4.8c1.2-1.6,2.6-2.2,4.2-1.8c0.913,0.228,1.565,0.89,2.288,1.624 c1.177,1.194,2.538,2.576,5.512,2.576c3.2,0,5.2-1.6,6-4.8c-1.2,1.6-2.6,2.2-4.2,1.8c-0.913-0.228-1.565-0.89-2.288-1.624 C10.337,13.382,8.976,12,6.001,12z">
                        </path>
                    </g>
                </svg>
                <p class="m-4">
                    Tailwind CSS
                </p>
            </div>

            <div class="skills flex flex-col items-center">
                <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M4.98488 2C3.61546 2 2.60217 3.19858 2.64753 4.49844C2.69105 5.74725 2.63451 7.36461 2.22732 8.68359C1.81892 10.0064 1.1282 10.8444 0 10.952V12.1666C1.1282 12.2742 1.81892 13.1122 2.22732 14.4351C2.63451 15.754 2.69105 17.3714 2.64753 18.6202C2.60217 19.9199 3.61546 21.1186 4.98508 21.1186H19.0169C20.3864 21.1186 21.3995 19.9201 21.3541 18.6202C21.3106 17.3714 21.3671 15.754 21.7743 14.4351C22.1829 13.1122 22.8718 12.2742 24 12.1666V10.952C22.8718 10.8444 22.1829 10.0064 21.7743 8.68359C21.3671 7.36481 21.3106 5.74725 21.3541 4.49844C21.3995 3.19878 20.3864 2 19.0169 2H4.98468H4.98488ZM16.2712 13.7687C16.2712 15.5586 14.9361 16.6441 12.7206 16.6441H8.94915C8.84127 16.6441 8.7378 16.6012 8.66152 16.5249C8.58523 16.4486 8.54237 16.3452 8.54237 16.2373V6.88136C8.54237 6.77347 8.58523 6.67001 8.66152 6.59372C8.7378 6.51743 8.84127 6.47458 8.94915 6.47458H12.6991C14.5464 6.47458 15.7588 7.47525 15.7588 9.01166C15.7588 10.09 14.9433 11.0555 13.9041 11.2245V11.2809C15.3187 11.4361 16.2712 12.4156 16.2712 13.7687ZM12.3094 7.76407H10.1589V10.8015H11.9701C13.3702 10.8015 14.1423 10.2377 14.1423 9.2299C14.1423 8.28556 13.4784 7.76407 12.3094 7.76407ZM10.1589 12.0068V15.3542H12.3885C13.8462 15.3542 14.6184 14.7692 14.6184 13.6699C14.6184 12.5704 13.8246 12.0066 12.2947 12.0066H10.1589V12.0068Z"
                            fill="currentColor"></path>
                    </g>
                </svg>
                <p class="m-4">
                    Bootstrap
                </p>
            </div>

            <div class="skills flex flex-col items-center">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path
                            d="M11.9999 8.66667H8.66658M11.9999 8.66667V2H8.66659C6.82564 2 5.33325 3.49238 5.33325 5.33333C5.33325 7.17428 6.82563 8.66667 8.66658 8.66667M11.9999 8.66667V15.3333H8.66658M11.9999 8.66667L15.3333 8.66667M8.66658 8.66667C6.82563 8.66667 5.33325 10.1591 5.33325 12C5.33325 13.8409 6.82563 15.3333 8.66658 15.3333M8.66658 15.3333C6.82564 15.3333 5.33325 16.8257 5.33325 18.6667C5.33325 20.5076 6.82564 22 8.66659 22C10.5075 22 11.9999 20.5076 11.9999 18.6667M15.3333 8.66667C13.4923 8.66667 11.9999 10.1591 11.9999 12C11.9999 13.8409 13.4923 15.3333 15.3333 15.3333C17.1742 15.3333 18.6666 13.8409 18.6666 12C18.6666 10.1591 17.1742 8.66667 15.3333 8.66667ZM15.3333 8.66667C17.1742 8.66667 18.6666 7.17428 18.6666 5.33333C18.6666 3.49238 17.1742 2 15.3333 2"
                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                </svg>
                <p class="m-4">
                    Figma
                </p>
            </div>

            <div class="skills flex flex-col items-center">
                <svg fill="currentColor" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg" width="30"
                    height="30">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <title>Affinity Designer icon</title>
                        <path
                            d="M10.44 0L0 18.083v5.197a.72.72 0 00.713.72h10.023L5.7 15.277 14.52 0zm5.16 0l-4.86 8.418 3.718 6.439H24V.718A.72.72 0 0023.28 0zm-5.4 9.353l-2.064 3.575a1.289 1.289 0 000 1.288c.23.4.656.64 1.117.64h4.125zm-3.122 6.44L11.816 24h11.471a.72.72 0 00.713-.718v-7.49Z">
                        </path>
                    </g>
                </svg>
                <p class="m-4">
                    Affinity Designer
                </p>
            </div>

            <div class="skills flex flex-col items-center">
                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="30" height="30">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <g>
                            <path
                                d="M42.3,11.8,25.7,2.4a4,4,0,0,0-3.4,0L5.7,11.8A3.4,3.4,0,0,0,4,14.7V33.3a3.4,3.4,0,0,0,1.7,2.9l4.4,2.5a7.2,7.2,0,0,0,3.8,1c3.1,0,4.9-1.9,4.9-5.1V16.3a.5.5,0,0,0-.5-.5H16.2a.5.5,0,0,0-.5.5V34.6c0,1.5-1.5,2.9-3.9,1.7L7.2,33.7a.5.5,0,0,1-.2-.4V14.7a.8.8,0,0,1,.2-.5L23.7,4.9h.6l16.5,9.3a.8.8,0,0,1,.2.5V33.3a.5.5,0,0,1-.2.4L24.3,43a.6.6,0,0,1-.6,0l-4.2-2.4a.3.3,0,0,0-.4,0l-2.5,1.1c-.3.1-.7.2.1.7l5.6,3.1a3.1,3.1,0,0,0,3.4,0l16.6-9.3A3.4,3.4,0,0,0,44,33.3V14.7A3.4,3.4,0,0,0,42.3,11.8Z">
                            </path>
                            <path
                                d="M29.1,30.3c-4.4,0-5.3-1-5.7-3.1a.4.4,0,0,0-.4-.4H20.8a.4.4,0,0,0-.4.4c0,2.7,1.5,6,8.7,6,5.2,0,8.2-2,8.2-5.5s-2.4-4.5-7.5-5.1-5.6-1-5.6-2.2.4-2.2,4.2-2.2,4.7.7,5.2,2.9a.5.5,0,0,0,.5.4h2.1l.4-.2a.4.4,0,0,0,.1-.3c-.3-3.9-3-5.7-8.3-5.7s-7.5,2-7.5,5.2,2.8,4.5,7.3,5,5.9,1.2,5.9,2.3S32.6,30.3,29.1,30.3Z">
                            </path>
                        </g>
                    </g>
                </svg>
                <p class="m-4">
                    Node.js
                </p>
            </div>

            <div class="skills flex flex-col items-center">
        <svg fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" width="30" height=30>
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M18.036 0.131c-8.765-1.12-16.781 5.067-17.905 13.833-1.12 8.765 5.067 16.781 13.833 17.905 8.765 1.12 16.781-5.067 17.9-13.833 1.125-8.765-5.061-16.781-13.828-17.905zM21.328 10.115c-0.297 0-0.579 0.12-0.787 0.333l-5.937 5.932-0.411-0.407-0.855-0.859c5.849-5.833 6.907-5.891 7.989-5zM14.849 16.593l5.916-5.921c0.328-0.344 0.875-0.339 1.204 0.005 0.323 0.349 0.291 0.896-0.073 1.197l-6.265 5.5zM15.287 17.521l-1.469 0.317c-0.031 0.005-0.072-0.011-0.088-0.047-0.016-0.032-0.011-0.068 0.016-0.095l0.859-0.859zM11.547 16.907l1.568-1.563 1.172 1.172-2.641 0.567c-0.047 0.011-0.093-0.009-0.115-0.052-0.025-0.041-0.015-0.093 0.016-0.124zM6.688 24.984c-0.057-0.005-0.1-0.057-0.095-0.109 0.005-0.025 0.016-0.047 0.032-0.063h0.005l1.26-1.26 1.631 1.631zM9.921 23.307c-0.124 0.068-0.187 0.209-0.156 0.344l0.271 1.152c0.043 0.167-0.161 0.28-0.281 0.156h-0.005l-1.635-1.636 5.016-5.011 2.427-0.525 1.161 1.167c-1.672 1.468-3.959 2.932-6.797 4.353zM16.959 18.74l-1.12-1.12 6.265-5.5c0.057-0.052 0.109-0.109 0.156-0.167-0.192 1.792-2.703 4.323-5.301 6.787zM21.839 10.125h-0.005c-2.183-2.193 0.901-5.563 3.276-3.584l-2.145 2.152c-0.063 0.061-0.063 0.167 0 0.228l1.661 1.663c-0.932 0.463-2.052 0.276-2.787-0.459zM25.271 10.125c-0.109 0.109-0.229 0.208-0.359 0.291l-1.609-1.609 2.041-2.047c0.885 0.964 0.849 2.443-0.073 3.365zM25.14 8.068c-0.067 0.047-0.093 0.129-0.072 0.208 0.099 0.197 0.072 0.432-0.068 0.599-0.068 0.084-0.052 0.199 0.031 0.265 0.032 0.021 0.068 0.037 0.109 0.037 0.057 0 0.111-0.021 0.141-0.063 0.235-0.281 0.281-0.677 0.12-1.005-0.063-0.083-0.177-0.104-0.261-0.041z">
                </path>
            </g>
        </svg>
        <p class="m-4">
            Postman
        </p>
    </div>
            
        </div>
        `,
  });
  return page;
}
