import {createElement} from './utilis.js';

export default function contact() {
	const contact = createElement({
		type: 'section',
		id: 'contact',
		classes: `flex flex-col items-center text-primary bg-secondary container mx-auto  rounded-2xl mt-10 p-6`,
		content: `
    <h1 class="animate-title text-4xl font-bold">Contact me</h1>

    <div class="flex flex-col lg:flex-row items-center justify-around w-full mt-10 gap-4">
            <form id="contactForm" class="contact flex flex-col items-center  p-4" action="http://localhost:1337/submit-form" method="POST">
              <input type="text" name="name" placeholder="Name" class="w-80 md:w-96 h-10 rounded-md p-2 mb-4 text-secondary" required/>
              <input type="email" name="email" placeholder="Email" class="w-80 md:w-96 h-10 rounded-md p-2 mb-4 text-secondary" required/>
              <textarea name="message" placeholder="Message" class="w-80 md:w-96 h-40 rounded-md p-2 mb-4 text-secondary" required></textarea>
                    <button class="w-40 h-10 mt-4 bg-primary text-secondary rounded-md" type="submit">Send</button>
            </form>

            </div>
            `,
	});

	const form = contact.querySelector('#contactForm');

	const disableForm = (form, disable) => {
		const elements = form.querySelectorAll('input, textarea, button');
		elements.forEach((element) => (element.disabled = disable));
	};

	const toggleSubmitButton = (button, isSubmitting) => {
		button.textContent = isSubmitting ? 'Sending...' : 'Send';
		button.classList.toggle('animate-bounce', isSubmitting);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		const form = event.target;
		const formData = new FormData(form);
		const submitButton = form.querySelector('button[type="submit"]');

		// Disable the form and animate the button
		disableForm(form, true);
		toggleSubmitButton(submitButton, true);

		try {
			const response = await fetch(
				'https://portfolio-9pyt.onrender.com/submit-form',
				{
					method: 'POST',
					headers: {'Content-Type': 'application/x-www-form-urlencoded'},
					body: new URLSearchParams(formData).toString(),
				}
			);

			const message = await response.text();
			if (response.ok) {
				form.reset();
				alert(message);
			} else {
				alert(`Error submitting form: ${message}`);
			}
		} catch (error) {
			console.error(error);
			alert('Error submitting form. Please try again later.');
		} finally {
			// Enable the form and reset the button
			disableForm(form, false);
			toggleSubmitButton(submitButton, false);
		}
	};

	form.addEventListener('submit', handleSubmit);

	return contact;
}
